import { gql } from 'graphql-request'

export const createProjectGoal = gql`
  mutation($pharmacyClusterId: Int!, $quarterId: Int!, $projectId: Int!, $goal: Int!) {
    createClusterQuarterProjectGoal(pharmacy_cluster_id: $pharmacyClusterId, quarter_id: $quarterId, project_id: $projectId, goal: $goal) {
      id
    }
  }
`

export const createClusterProjectGoal = gql`
  mutation createClusterProjectGoal(
    $pharmacyClusterId: Int!, 
    $from: Date!, 
    $to: Date!
    $projectId: Int!, 
    $goal: Int!) {
      createClusterProjectGoal(
      pharmacy_cluster_id: $pharmacyClusterId, 
      from: $from, 
      to: $to,
      project_id: $projectId, 
      goal: $goal) {
        id
      }
    }
`